/* Autofill */

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #252526 inset !important;

}

.dark input:-webkit-autofill {
  -webkit-text-fill-color: #cecece !important;
}

.dark input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark textarea::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-select-selection-placeholder{
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-table-expanded-row .ant-table-thead .ant-table-cell:hover{
  background-color: #1d1d1d;
}


/* Login */

.dark .folders {
  font-weight: 500;
  padding: 9px 20px;
  padding-right: 8px;
  background-color: #1d1d1d;
  border-bottom: 1px solid #333;
  color: #cecece;
}

.dark .paginate {
  background-color: #2f2f2f;
  margin: 0px 0px;
  border-bottom: 1px solid #333333;
  color: #cecece;
}

.dark .ant-input {
  border: 1px solid #333333;
}


.dark .ant-input[type=text] {
  background-color: #252526;
}

.dark .ant-form-item-control-input-content>input {
  border: 1px solid #333333 !important;
}

.dark .ant-form-item-extra{
  color: #ddd;
}

.dark .ant-input {
  background-color: #252526;
  color: #cecece;
}

.dark .ant-input-affix-wrapper {
  background-color: #252526;
}

.dark .clean-input .ant-input-affix-wrapper {
  border: 1px solid #333333 !important;
}

.dark .ant-input-password {
  background-color: #252526;
  border: 1px solid #333333 !important;
}

.dark .ant-input-password-icon {
  color: #666666;
}

.dark .ant-form-item-explain-error {
  opacity: 0.5;
}


/* General  */

.dark .ant-layout {
  background-color: #1e1e1e;
}

.dark {
  background-color: #1e1e1e;
  height: 100%;
  color: #cecece;
}

.dark p {
  color: rgba(206, 206, 206, .5);
}

.dark .ant-card {
  background-color: #252526;
  border: 1px solid #333333 !important;
}

.dark .ant-card-hoverable:hover {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.dark label {
  color: #cecece;
}

/* Nav */

.dark .navWrapper {
  background-color: #252526;
  border-bottom: 1px solid #333;
}

.dark .ant-menu.ant-menu-dark,
.dark .ant-menu-dark .ant-menu-sub,
.dark .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #252526 !important;
}

.dark .accountCard {
  background-color: #252526;
}

/* Layout */

.dark .odd{
  background-color: #232323;
}

.dark .even{
  background-color: #171717;
}


.dark .bw{
  color: #fff;
}

.dark .layoutHeader {
  background-color: #2f2f2f;
  border-bottom: 1px solid #333333;
}

.dark .layoutWrapper {
  border: 1px solid #333333;
}

.dark .layoutWrapper .ant-layout {
  background-color: #252526;
}

.dark .ant-layout-sider-light {
  background-color: #252526;
}

/* Overwrites */

.dark .ant-divider-dashed,
.dark .ant-divider {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .ant-form-item-has-error .ant-input,
.dark .ant-form-item-has-error .ant-input-affix-wrapper,
.dark .ant-form-item-has-error .ant-input:hover,
.dark .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #252526;
}

.dark .ant-typography,
.dark h1.ant-typography,
.dark .ant-typography h1,
.dark h2.ant-typography,
.dark .ant-typography h2,
.dark h3.ant-typography,
.dark .ant-typography h3,
.dark h4.ant-typography,
.dark .ant-typography h4,
.dark h5.ant-typography,
.dark .ant-typography h5 {
  color: #cecece;
}

.dark .ant-input-affix-wrapper {
  color: rgba(206, 206, 206, 1);
}

.dark .ant-form {
  color: rgba(206, 206, 206, .5);
}

.dark .ant-typography.ant-typography-secondary {
  color: rgba(206, 206, 206, .5);
}

.dark .ant-breadcrumb-link {
  color: #666;
}

.dark .ant-breadcrumb-separator {
  color: #444;
}

/* Misc */




.dark .largeTable td {
  font-size: 11px !important;
  padding: 8px 5px !important;
  padding-left: 8px !important;
  background-color: #252526 !important;
}

.dark .largeTable th {
  font-size: 13px;
  overflow: hidden;
  font-weight: normal !important;
  padding: 4px 3px !important;
  padding-left: 8px !important;
  background-color: #252526 !important;
}


.dark .smallTable th {
  font-size: 13px;
  overflow: hidden;
  color: #aaa !important;
  font-weight: normal !important;
  padding: 4px 3px !important;
  padding-left: 8px !important;
  background-color: #252526 !important;
}

.dark .smallTable td {
  font-size: 11px !important;
  padding: 0px 0px !important;
  padding-left: 8px !important;
  background-color: #252526 !important;
}


.dark .smallTable .ant-table-footer {
  padding: 0px !important;
  background-color: #1d1d1d !important;
}

.dark .smallTable .ant-table-title {
  padding: 0px !important;
  background-color: #1d1d1d !important;
}



.dark .htmlcolor  {
  color: rgba(206, 206, 206, .5) !important;
}

.dark .htmlcolor * {
  color: rgba(206, 206, 206, .5) !important;
}

.dark .mediumTable th {
  color: #aaa !important;
  background-color: #252526 !important;
}

.dark .mediumTable td {
  background-color: #1d1d1d !important;
  border-bottom: 1px solid #333;
}

.dark .mediumTable .ant-table-footer {
  background-color: #1d1d1d !important;
}

.dark .mediumTable .ant-table-title {
  background-color: #1d1d1d !important;
}

.dark .peerTable td {
  background-color: #1d1d1d !important;
}






.dark .fullTable th {
  color: #aaa !important;
  background-color: #252526 !important;
}

.dark .fullTable td {
  background-color: #252526 !important;
}

.dark .selectedRow {
  background-color: #252526 !important;
}



.dark .pubstockTable .ant-table-footer {
  background-color: #1d1d1d !important;
}

.dark .pubstockTable .ant-table-title {
  background-color: #1d1d1d !important;
}





.dark .presetTable th {
  color: #666 !important;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  background-color: #333 !important;
}

.dark .presetTable td {
  background-color: #252526 !important;
}

.dark .pubstockTable th {
  color: #aaa !important;
  background-color: #252526 !important;
}

.dark .pubstockTable td {
  background-color: #252526 !important;
}


.dark .groupBG{
  background-color: #2978c2 !important;
  color: #fff;
  border: none;
  
}

.dark .groupBG[disabled]{
  background-color: #363637 !important;
  color: #5b6263 !important;
  border: 1px solid #333333 !important;
}


.dark .rowAdjusted {
  color: #C71585;
}

.dark .rowReturns {
  color: #9932CC;
}

.dark .rowReceived {
  color: #1581E9;
}

.dark .rowBestSell {
  color: #F12A46;
}

.dark .rowSold {
  color: rgba(206, 206, 206, .8);
}

.dark .rowPeer {
  color: #A52A2A;
}

.dark .rowAdj {
  color: #C71585;
}

.dark .rowRetn {
  color: #9932CC;
}

.dark .rowRawData {
  color: #B90DB9;
}

.dark .rowFuture {
  color: #185285;
}

.dark .rowRcvd {
  color: #e77e21;
}


.dark .successColor{
  color:  #4f9818;
 }
 
 .dark .warningColor{
   color: #E77E21;
 }
 
 .dark .disabledColor{
   color: #999999;
 }
 
 .dark .futureColor{
   color: #61d6d6 !important;
 }
 
 .dark .bestsellerColor{
   color: #F12A46;
 }
 
 .dark .peerColor{
   color: #A52A2A;
 }
 
 .dark .favColor{
   color: #ffcf17;
 }
 
 .dark .highlightColor{
   color: #49b7e0;
 }

 .dark .returnedColor{
   color: #9932cc;
 }

.dark .rowSold {
  color: rgba(206, 206, 206, .8);
}

.dark .rowPeer {
  color: #d13232;
}

.dark .highlightRow{
  background-color: #ff0099 !important;
  color: #ffffff;
}

/* .dark .highlightRow td:nth-child(n-1) {
  color: #ffffff;
} */

.dark .rowHighlightHover td:nth-child(n-1){
  transition: background-color 500ms linear;
}

.dark .rowHighlightHover>td {
  background: unset !important;
}

.dark .rowHighlightHover:hover td:nth-child(n-1){
  cursor: pointer;
  transition: background-color 500ms linear;
  background: #1f1f1f !important;
  color: #aaa;
}

.dark .highlightBestSell{
  color: #A4031A;
}

.dark .evenRowColour {
  background: #3d3d3d !important;
}

/* 
.dark .extra-peer {
  background-color: #252526;
  color: #d13232;
}

.dark .extra-backroom {
  background-color: #252526;
  font-weight: 500;
}

.dark .extra-held {
  background-color: #d13232;
  font-weight: 500;
}

.dark .extra-pending_return {
  background-color: #252526;
  font-weight: 500;
}

.dark .extra-special_order {
  background-color: #fff362;
  color: #000;
  font-weight: 500;
}

.dark .extra-total_onhand {
  background-color: #252526;
  font-weight: 500;
}

.dark .extra-minimum_onhand {
  background-color: #252526;
  font-weight: 500;
}

.dark .extra-pending_onorder {
  background-color: #252526;
  font-weight: 500;
}

.dark .extra-lbl-backroom {
  color: rgba(206, 206, 206, .8);
}

.dark .extra-lbl-held {
  color: rgba(206, 206, 206, .8);
}

.dark .extra-lbl-pending_return {
  color: rgba(206, 206, 206, .8);
}

.dark .extra-lbl-special_order {
  color: orange;
} */

/* .dark .extra-lbl-total_onhand {
  color: rgba(206, 206, 206, .8);
} */

/* .dark .extra-lbl-minimum_onhand {
  color: rgba(206, 206, 206, .8);
} */

.dark .rowHover:hover{
  background-color: #1d1d1d !important;
}
.dark .extra-lbl-minimum_onhand{ color: #cecece;}

.dark .extra-lbl-pending_onorder {
  /* color: rgba(206, 206, 206, .8); */
}

.dark .ant-input-clear-icon svg{
  fill: rgba(206, 206, 206, .8);
}

.dark .bminput{
  color: #fff;
  margin-top: 1px;
  padding-right: 1px;
  background-color: #0640a0;
}

.dark .bminput .ant-input[type=text]{
  background-color: #007EF5 !important;
}

.dark .bminput input{
  border: none;
  border-radius: 0px;
  background-color: #007EF5;
  color: #fff;
}

.dark .ant-tag-blue {
  background-color: #252526;
}

.dark .ant-tag-orange {
  border-color: #c08b36;
  background-color: #403a33;
}


.dark .itemHistory th {
  color: #aaa !important;
  background-color: #252526 !important;
}

.dark .itemHistory td {
  background-color: #252526 !important;
}


.dark .itemHistory {
  border: 1px solid #444;
}


.dark .bmLabel {
  color: #888;
  background-color: #262626;
}

.dark .bmValue {
  color: rgba(206, 206, 206, 1);
  background-color: #262626;
}

.dark .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
  color: rgba(206, 206, 206, 1);
}

.dark .jodit-container:not(.jodit_inline) .jodit-workplace{
  background-color:#1f1f1f;
  border: 0 solid #333;
}

.dark .jodit-toolbar__box:not(:empty){
  border-bottom: 1px solid #333;
  background-color: #2f2f2f;
}

.dark .jodit-toolbar-button__trigger:hover:not([disabled]){
  background-color: #666;
}

.dark .jodit-container:not(.jodit_inline){
  background-color: #2f2f2f;
  border: 1px solid #333;
}

.dark .jodit-toolbar__box:not(:empty) .jodit-toolbar-editor-collection:after{
  background-color: #333;
}

.dark .jodit-toolbar-button__button:hover:not([disabled]){
  background-color: #666;
}

.jodit-toolbar-button{
  border: none;
}

.dark .jodit-popup__content{
  background: #2f2f2f;
}

.dark .jodit-ui-button:hover:not([disabled]){
background-color: #444;
}

.dark .jodit-toolbar-editor-collection_mode_horizontal:after{
  background-color: #444;
}

.dark .jodit-toolbar-button__text{
  color: #ddd;
}

.dark .jodit-ui-button__text{
  color: #ddd;
}
.dark .jodit-ui-input__wrapper{
  border: 1px solid #444;
}

.dark .jodit-ui-input__input{
  background: #252526;
}

.jodit-toolbar-button__trigger{
  fill: #888;
}
.dark .jodit-icon{
  fill: #888;
}

.dark .cf {
  fill: rgba(206, 206, 206, 1);
}




.dark .c {
  color: rgba(206, 206, 206, 1);
}

.dark .c2 {
  color: rgba(206, 206, 206, .7);
}


.dark .customerCart .ant-table-title{
  background-color: #1d1d1d;
  border-bottom: 1px solid #333;
}

.dark .ant-input-group-addon {
  border: 1px solid #434343;
}
.dark .clean-input .ant-input-group-addon:first-child{
  border: 1px solid #333;
  border-right: 0px;
  border-radius: 2px 0px 0px 2px;

}

.dark .bc {
  border-color: #444 !important;
}

.dark .bc2 {
  border-color: #252526 !important;
}

.dark .bc-light {
  border-right-color: #444 !important;
  border-bottom-color: #444 !important;
  border-top-color: #444 !important;
}

.dark .itemTabs .ant-tabs-nav-wrap {
  border-bottom: 1px solid #444;
}

.dark .itemTabs .ant-tabs-nav-wrap {
  border-bottom: 1px solid #444;
}

.dark .extraTabs .ant-tabs-tab-active{
  border-bottom-color : #444 !important;
}

.dark .browse-list-title{
  color: #aaa;

}



.dark .extraTabs .ant-tabs-tab-active, .dark .itemTabs .ant-tabs-tab-active small{
  color: rgba(206, 206, 206, 1);
}



.dark .itemTabs .ant-tabs-tab,
.dark .itemTabs .ant-tabs-tab {
  border-color: #333 !important;
  border-bottom: none !important;
}

.dark .itemTabs .ant-tabs-tab-active,
.dark .itemTabs .ant-tabs-tab-active {
  border-color: #444 !important;
  border-bottom: none !important;
}


.dark .cataloguebtn {
  background-color: #2f2f2f !important;
  border: 1px solid #444;
  color: #888;
  font-size: 11px !important;
}

.dark .cataloguebtn:hover {
  filter: brightness(1.0);
  color: #000;
  background-color: #2f2f2f !important;
  border: 1px solid #177ddc;
}

.dark .hideHover {
  background-color: #262626;
}

.dark .hideHover:hover {
  background-color: #262626;
}


.dark .closebar {
  display: block;
  background-color: #1d1d1d;
  margin: 0 -20px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 1px solid #333;
  border-left: 0px;
  border-right: 0px;

}

.dark .afix .ant-affix {
  background-color: #2f2f2f;
  border-bottom: 1px solid #333;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%);
}


.dark .ant-input-group-addon {
  background: #252526;
}

.dark .shadow-large {
  -webkit-box-shadow: 0px 3px 6px -1px #000;
  box-shadow: 0px 3px 6px -1px #000;
}


.dark .drag-selector .ant-select-selector{
  border-radius : 2px 0px 0px 2px !important;
  border: 1px solid #333333 !important;
  text-align: center;
  background-color: #252526 !important;
}

.dark .drag-selector .ant-upload-drag{
  border-radius : 0px 2px 2px 0px !important;
  width: 360px;
  height: 32px !important;
  border: 1px solid #333333 !important;
  border-left: 0px !important;
}

.dark .drag-selector .ant-upload{
  padding: 0px 6px !important;
  text-align: left;
  background-color: #252526 !important;
  font-size: 12px;
  color: #888;
}



/* .dark .filterpreset {
  background-color: #177ddc !important;
} */

.dark .bcg {
  background-color: #333;
}

.dark .bcg2 {
  background-color: #262626;
}

.dark .bcg3 {
  background-color: #2f2f2f;
}

.dark .bcg4 {
  background-color: #444;
}

.dark .bcg5 {
  background-color: #1c1c1c;
}


.dark .bdr {
  border: 1px solid #333;
}

.dark .ant-empty-img-simple {
  opacity: 0.2;
}

.dark .ant-empty-img-simple-ellipse {
  fill: #666;
}

.dark .ant-empty-description {
  color: #666;
}

.dark .shimbg {
  background-color: #252526;
}

.dark .borderLeft {
  border-left: 1px solid #333;
}

.dark .borderTop {
  border-top: 1px solid #333;
}

.dark .borderBottom {
  border-bottom: 1px solid #1e1e1e;
}

.dark .ant-input-affix-wrapper {
  border: 1px solid #333;
}

/* FORM ELEMENTS */

.dark .ant-input-textarea-show-count::after {
  color: rgba(255, 255, 255, .20);
}



.dark .ant-btn-fake-disabled {
  background-color: #333;
  color: rgba(255, 255, 255, .30);
  border-color: rgba(255, 255, 255, .10);
}


.dark .ant-btn[disabled] {
  background-color: #333;
  color: rgba(255, 255, 255, .30);
  border-color: rgba(255, 255, 255, .10);
}

.dark .ant-btn-dangerous,
.dark .ant-btn-dangerous:hover {
  background-color: #333;
}

.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #252526;
  border: 1px solid #333;
  color: #cecece;
}

.dark .ant-select-arrow {
  color: #cecece;
}

.dark .ant-switch-handle {
  color: #ccc;
}

.dark .ant-switch {
  background-color: rgba(255, 255, 255, .15);
}

.dark .ant-switch-checked {
  background-color: #1890ff;
}

.dark .ant-radio-inner {
  background-color: rgba(255, 255, 255, .15);
  border: 1px solid #454546;
}

.dark .ant-checkbox-inner {
  border: 1px solid #555;
  background-color: #252526;
}

.dark .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
}

.dark .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(255, 255, 255, 0.3);
  -webkit-animation-name: none;
  animation-name: none;
}

.dark .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343 !important;
}

.dark .ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: rgba(255, 255, 255, 0.08);
}

.dark .ant-checkbox-disabled+span {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
.dark .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
  background: #1e1e1e;
  border-color: #2c2c2c;
  color: rgba(206, 206, 206, .4);
}

.dark .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.dark .ant-tabs-tab-btn:focus,
.dark .ant-tabs-tab-remove:focus,
.dark .ant-tabs-tab-btn:active,
.dark .ant-tabs-tab-remove:active {
  color: rgba(206, 206, 206, .6);
}

.dark .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.dark .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  background: #444;
  border-color: #444;
}

/* SCROLLBAR */

.dark::-webkit-scrollbar-track {
  background-color: #171717;
}

.dark::-webkit-scrollbar-thumb {
  background-color: #424242
}




/* Ant Modal */
.dark .ant-modal {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-modal.ant-zoom-enter,
.dark .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45);
}

.dark .ant-modal-title {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-modal-content {
  background-color: #1f1f1f;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
}

.dark .ant-modal-close {

  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-modal-close:focus,
.dark .ant-modal-close:hover {
  color: rgba(255, 255, 255, 0.75);
}

.dark .ant-modal-header {
  color: rgba(255, 255, 255, 0.85);
  background: #1f1f1f;
  border-bottom: 1px solid #303030;
}

.dark .ant-modal-footer {
  border-top: 1px solid #303030;
}


.dark .ant-modal-confirm-body .ant-modal-confirm-title {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-modal-confirm-body .ant-modal-confirm-content {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
  color: #a61d24;
}

.dark .ant-modal-confirm-warning .ant-modal-confirm-body>.anticon,
.dark .ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
  color: #d89614;
}

.dark .ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
  color: #177ddc;
}

.dark .ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
  color: #49aa19;
}

.dark .ant-modal .ant-picker-clear,
.dark .ant-modal .ant-slider-handle,
.dark .ant-modal .ant-anchor-wrapper,
.dark .ant-modal .ant-collapse-content,
.dark .ant-modal .ant-timeline-item-head,
.dark .ant-modal .ant-card {
  background-color: #1f1f1f;
}

.dark .ant-modal .ant-transfer-list-header {
  background: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.dark .ant-modal tr.ant-table-expanded-row>td,
.dark .ant-modal tr.ant-table-expanded-row:hover>td {
  background: #272727;
}

.dark .ant-modal .ant-table.ant-table-small thead>tr>th {
  background-color: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table {
  background-color: #1f1f1f;
}

.dark .ant-modal .ant-table .ant-table-row-expand-icon {
  border: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table tfoot>tr>th,
.dark .ant-modal .ant-table tfoot>tr>td {
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table thead>tr>th {
  background-color: #272727;
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table tbody>tr>td {
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table tbody>tr>td.ant-table-cell-fix-left,
.dark .ant-modal .ant-table tbody>tr>td.ant-table-cell-fix-right {
  background-color: #1f1f1f;
}

.dark .ant-modal .ant-table tbody>tr.ant-table-row:hover>td {
  background: #303030;
}

.dark .ant-modal .ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table.ant-table-bordered thead>tr>th,
.dark .ant-modal .ant-table.ant-table-bordered tbody>tr>td,
.dark .ant-modal .ant-table.ant-table-bordered tfoot>tr>th,
.dark .ant-modal .ant-table.ant-table-bordered tfoot>tr>td {
  border-right: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table.ant-table-bordered table thead>tr:not(:last-child)>th {
  border-bottom: 1px solid #303030;
}

.dark .ant-modal .ant-table.ant-table-bordered .ant-table-container {
  border: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after {
  border-right: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #3a3a3a;
}

.dark .ant-modal .ant-table .ant-table-filter-trigger-container-open {
  background-color: #525252;
}

.dark .ant-modal .ant-picker-calendar-full {
  background-color: #1f1f1f;
}

.dark .ant-modal .ant-picker-calendar-full .ant-picker-panel {
  background-color: #1f1f1f;
}

.dark .ant-modal .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-top: 2px solid #3a3a3a;
}

.dark .ant-modal .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
}

.dark .ant-modal .ant-badge-count {
  box-shadow: 0 0 0 1px #1f1f1f;
}

.dark .ant-modal .ant-tree-show-line .ant-tree-switcher {
  background: #1f1f1f;
}


/* TABLE */

.dark .ant-table-expanded-row .ant-table-cell,
.ant-table-expanded-row .ant-table-cell:hover {
  background-color: #262626;
}


.dark .fullTable th {
  color: #aaa !important;
  background-color: #1d1d1d;
  border-radius: 0px !important;
}

.dark .fullTable td {
  background-color: #262626 !important;
}

.dark .ant-table-small .ant-table-thead>tr>th {
  background-color: #1d1d1d;
}

.dark .ant-table.ant-table-bordered>.ant-table-title {
  border: 1px solid #303030;
  border-bottom: 0;
}

.dark .ant-table.ant-table-bordered>.ant-table-container {
  border-left: 1px solid #303030;
}

.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
  border-right: 1px solid #303030;
}

.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr:not(:last-child)>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr:not(:last-child)>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr:not(:last-child)>th,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr:not(:last-child)>th {
  border-bottom: 1px solid #303030;
}

.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th::before,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th::before,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th::before,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th::before {
  background-color: transparent !important;
}

.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>.ant-table-cell-fix-right-first::after {
  border-right: 1px solid #303030;
}

.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td>.ant-table-expanded-row-fixed::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td>.ant-table-expanded-row-fixed::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td>.ant-table-expanded-row-fixed::after,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td>.ant-table-expanded-row-fixed::after {
  border-right: 1px solid #303030;
}

.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.dark .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border-top: 1px solid #303030;
}

.dark .tborder {
  border: 1px solid #333;
}

.dark .tborder2 {
  border: 1px solid #333;
}

.dark .tborder3 {
  border: 1px solid #333;
}

.dark .tborder2 .ant-table-title{
  padding: 0 !important;
  border-bottom: 1px solid #333;
}

.dark .tborder3 .ant-table-title{
  padding: 0 !important;
  border-bottom: 0px;
}



.dark .ant-table.ant-table-bordered>.ant-table-container {
  border: none;
  border-left: 1px solid #333;
}

.dark .tableBorder table {
  border: 1px solid #333;
  border-bottom: 0px;
}

.dark .cellBorder {
  border-right: 1px solid #333;
  font-weight: 600px;
}

.dark .cellBorderBold {
  border-right: 1px solid #333;
  font-weight: 600px;
}

.dark .cborder .ant-collapse-header {
  border-bottom: 1px solid #333;
}

.dark .ant-table.ant-table-bordered>.ant-table-footer {
  border: 1px solid #303030;
}

.dark .ant-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px #1d1d1d;
}

.dark .ant-table {
  color: rgba(255, 255, 255, 0.85);
  background: #262626;
}

.dark .ant-table-footer {
  color: rgba(255, 255, 255, 0.85);
  background: rgba(255, 255, 255, 0.04);
}

.dark .ant-table-thead>tr>th {
  color: rgba(255, 255, 255, 0.85);
  background: #1d1d1d;
  border-bottom: 1px solid #303030;
}

.dark .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: rgba(255, 255, 255, 0.08);
}

.dark .ant-table-tbody>tr>td {
  border-bottom: 1px solid #303030;
  /* background: #262626; */
}

.dark .ant-table-tbody>tr.ant-table-row:hover>td {
  background: #2f2f2f;
}

.dark .ant-table-tbody>tr.ant-table-row-selected>td {
  background: #1890ff;
  border-color: rgba(0, 0, 0, 0.03);
}

.dark .ant-table-tbody>tr.ant-table-row-selected:hover>td {
  background: #0e161f;
}

div.dark .ant-table-summary {
  box-shadow: 0 -1px 0 #303030;
}

.dark .ant-table-summary>tr>th,
.dark .ant-table-summary>tr>td {
  border-bottom: 1px solid #303030;
}

.dark .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #1f1f1f;
}

.dark .ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}

.dark .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.dark .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #222;
}

.dark .ant-table-thead th.ant-table-column-sort {
  background: #2f2f2f;
}

.dark .ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}

.dark td.ant-table-column-sort {
  background: rgba(255, 255, 255, 0.01);
}

.dark .ant-table-column-sorter {
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}

.dark .ant-table-column-sorter-up.active,
.dark .ant-table-column-sorter-down.active {
  color: #177ddc;
}

.dark .ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}

.dark .ant-table-filter-trigger {
  color: #bfbfbf;
}

.dark .ant-table-filter-trigger:hover {
  color: rgba(255, 255, 255, 0.45);
  background: #434343;
}

.dark .ant-table-filter-trigger.active {
  color: #177ddc;
}

.dark .ant-table-filter-dropdown {
  color: rgba(255, 255, 255, 0.85);
  background-color: #1f1f1f;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
}

.dark .ant-table-filter-dropdown-btns {
  background-color: #1f1f1f;
  border-top: 1px solid #303030;
}

.dark table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}

.dark .ant-table-selection-extra .anticon {
  color: #bfbfbf;
}

.dark .ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}

.dark .ant-table-row-expand-icon {
  color: #177ddc;
  background: transparent;
  border: 1px solid #303030;
}

.dark .ant-table-row-expand-icon:focus,
.dark .ant-table-row-expand-icon:hover {
  color: #40a9ff;
}

.dark .ant-table-row-expand-icon:active {
  color: #388ed3;
}

.dark .ant-table-row-expand-icon:focus,
.dark .ant-table-row-expand-icon:hover,
.dark .ant-table-row-expand-icon:active {
  border-color: currentColor;
}

.dark .ant-table-row-expand-icon::before,
.dark .ant-table-row-expand-icon::after {
  background: currentColor;
}

.dark .ant-table-row-expand-icon-spaced {
  background: transparent;
}

.dark tr.ant-table-expanded-row>td,
.dark tr.ant-table-expanded-row:hover>td {
  background: #1d1d1d;
}

.dark .ant-table-empty .ant-table-tbody>tr.ant-table-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: #262626;
}

.dark .ant-table-cell-fix-left,
.dark .ant-table-cell-fix-right {
  background: #262626;
}

.dark .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
}

.dark .ant-table-ping-left .ant-table-cell-fix-left-first::after,
.dark .ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.45);
}

.dark .ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}

.dark .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
}

.dark .ant-table-ping-right .ant-table-cell-fix-right-first::after,
.dark .ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.45);
}

.dark .ant-table-sticky-holder {
  background: #262626;
}

.dark .ant-table-sticky-scroll {
  background: #fcfcfc;
  border-top: 1px solid #303030;
  opacity: 0.6;
}

.dark .ant-table-sticky-scroll-bar {
  background-color: rgba(0, 0, 0, 0.35);
}

.dark .ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.dark .ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}

.dark .player-wrapper{
  position: relative;
  padding-top: 56.25%;
}

.dark .react-player{
  position: absolute;
  top: 0;
  left: 0;
}

/* Picker */


.dark .ant-picker {
  color: rgba(255, 255, 255, 0.85);
  background-color: #252526;
  border: 1px solid #434343;
}

.dark .ant-picker:hover,
.dark .ant-picker-focused {
  border-color: #40a9ff;
}

.dark .ant-picker-focused {
  border-color: #177ddc;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
}

.dark .ant-picker.ant-picker-disabled {
  background: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.dark .ant-picker-input>input {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-picker-input>input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-input>input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-input>input:hover {
  border-color: #40a9ff;
}

.dark .ant-picker-input>input:focus,
.dark .ant-picker-input>input-focused {
  border-color: #177ddc;

}

.dark .ant-picker-input>input-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-picker-input>input-disabled:hover {
  border-color: #434343;
}

.dark .ant-picker-input>input[disabled] {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-picker-input>input[disabled]:hover {
  border-color: #434343;
}

.dark .ant-picker-input-placeholder>input {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-suffix {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-clear {
  color: rgba(255, 255, 255, 0.3);
  background: #141414;
}

.dark .ant-picker-clear:hover {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-picker-separator {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-focused .ant-picker-separator {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-picker-range .ant-picker-active-bar {
  background: #177ddc;
}

.ant-picker-dropdown {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-picker-ranges .ant-picker-preset>.ant-tag-blue {
  color: #177ddc;
  background: #1890ff;
  border-color: #153450;
}



.dark .ant-picker-range-arrow {
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
}

.dark .ant-picker-range-arrow::after {
  border: 5px solid #303030;
  border-color: #1f1f1f #1f1f1f transparent transparent;
}

.dark .ant-picker-panel-container {
  background: #1f1f1f;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
}

.dark .ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #303030;
}

.dark .ant-picker-panel {
  background: #1f1f1f;
  border: 1px solid #303030;
}

.dark .ant-picker-panel-focused {
  border-color: #177ddc;
}

.dark .ant-picker-header {
  color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid #303030;
}

.dark .ant-picker-header button {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-header>button:hover {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-picker-header-view button:hover {
  color: #177ddc;
}

.dark .ant-picker-content th {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-picker-cell {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-cell-in-view {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.dark .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: rgba(255, 255, 255, 0.08);
}

.dark .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #177ddc;
}

.dark .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #1890ff;
}

.dark .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #177ddc;
}

.dark .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #1890ff;
}

.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border-top: 1px dashed #0e4980;
  border-bottom: 1px dashed #0e4980;
}

.dark .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.dark .ant-picker-panel> :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.dark .ant-picker-panel> :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #06213a;
}

.dark .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.dark .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  background: #06213a;
}

.dark tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
.dark tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed #0e4980;
}

.dark tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
.dark tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.dark .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed #0e4980;
}

.dark .ant-picker-cell-disabled {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-cell-disabled::before {
  background: #303030;
}

.dark .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(255, 255, 255, 0.3);
}

.dark .ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #303030;
}

.dark .ant-picker-today-btn {
  color: #177ddc;
}

.dark .ant-picker-today-btn:hover {
  color: #40a9ff;
}

.dark .ant-picker-today-btn:active {
  color: #388ed3;
}

.dark .ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(255, 255, 255, 0.3);
}

.dark icker-year-panel .ant-picker-cell-range-hover-start::after,
.dark .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.dark .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed #0e4980;
}

.dark .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.dark .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.dark .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  border-right: 1px dashed #0e4980;
}

.dark .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.dark .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.dark .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed #0e4980;
}

.dark .ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.dark .ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.dark .ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  border-left: 1px dashed #0e4980;
}

.dark .ant-picker-week-panel-row:hover td {
  background: rgba(255, 255, 255, 0.08);
}

.dark .ant-picker-week-panel-row-selected td,
.dark .ant-picker-week-panel-row-selected:hover td {
  background: #177ddc;
}

.dark .ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.dark .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}

.dark .ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.dark .ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}

.dark .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.dark .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}

.dark .ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #303030;
}

.dark .ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #303030;
}

.dark .ant-picker-time-panel-column-active {
  background: rgba(17, 27, 38, 0.2);
}

.dark .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: rgba(255, 255, 255, 0.08);
}

.dark .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #1890ff;
}

.dark .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(255, 255, 255, 0.3);
}

/* TREE */

.dark .ant-tree {
  background-color: #252526;
}

.dark .ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: rgba(255, 255, 255, 0.08);
}

.dark .ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}

.dark .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.dark .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #177ddc;
}

.dark .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}

.dark .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
}

.dark .ant-tree-checkbox {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.dark .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.dark .ant-tree-checkbox-input:focus+.ant-tree-checkbox-inner {
  border-color: #177ddc;
}

.dark .ant-tree-checkbox-checked::after {
  border: 1px solid #177ddc;
}

.dark .ant-tree-checkbox-inner {
  border: 1px solid #434343;
}

.dark .ant-tree-checkbox-inner::after {
  border: 2px solid #fff;
}

.dark .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border: 2px solid #fff;
}

.dark .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #177ddc;
  border-color: #177ddc;
}

.dark .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(255, 255, 255, 0.3);
}

.dark .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343 !important;
}

.dark .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: rgba(255, 255, 255, 0.08);
}

.dark .ant-tree-checkbox-disabled+span {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-tree-checkbox-wrapper {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-tree-checkbox-group {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: transparent;
  border-color: #434343;
}

.dark .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: #177ddc;
}

.dark .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
}

.dark .ant-tree {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #111b26;
}

.dark .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: rgba(255, 255, 255, 0.08);
}

.dark .ant-tree-switcher-loading-icon {
  color: #177ddc;
}

.dark .ant-tree-switcher-leaf-line::before {
  border-left: 1px solid #d9d9d9;
}

.dark .ant-tree-switcher-leaf-line::after {
  border-bottom: 1px solid #d9d9d9;
}

.dark .ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.dark .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #11263c;
}

.dark .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  background-color: #177ddc;
}

.dark .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  border: 2px solid #177ddc;
}

.dark .ant-tree .ant-tree-treenode.drop-container>[draggable] {
  box-shadow: 0 0 0 2px #177ddc;
}

.dark .ant-tree-show-line .ant-tree-indent-unit::before {
  border-right: 1px solid #434343;
}

.dark .ant-tree-show-line .ant-tree-switcher {
  background: #141414;
}

.dark .ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  border-left: 1px solid #434343;
}

/* PAGINATION */

.dark .ant-pagination {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-pagination a{
  color: #1890ff;
}

.dark .ant-pagination-disabled a{
  color: #797979;
}

.dark .ant-pagination-item {
  border: 1px solid #797979;
}

.dark .ant-pagination-item a {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-pagination-item:focus-visible,
.dark .ant-pagination-item:hover {
  border-color: #1890ff;
}

.dark .ant-pagination-item:focus-visible a,
.dark .ant-pagination-item:hover a {
  color: #1890ff;
}

.dark .ant-pagination-item-active {
  background: transparent;
  border-color: #1890ff;
}

.dark .ant-pagination-item-active a {
  color: #1890ff;
}

.dark .ant-pagination-item-active:focus-visible,
.dark .ant-pagination-item-active:hover {
  border-color: #1890ff;
}

.dark .ant-pagination-item-active:focus-visible a,
.dark .ant-pagination-item-active:hover a {
  color: #1890ff;
}

.dark .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.dark .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1890ff;
}

.dark .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.dark .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-pagination-prev,
.dark .ant-pagination-next,
.dark .ant-pagination-jump-prev,
.dark .ant-pagination-jump-next {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-pagination-prev button,
.dark .ant-pagination-next button {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-pagination-prev:hover button,
.dark .ant-pagination-next:hover button {
  border-color: #1890ff;
}

.dark .ant-pagination-prev .ant-pagination-item-link,
.dark .ant-pagination-next .ant-pagination-item-link {
  border: 1px solid #434343;
}

.dark .ant-pagination-prev:focus-visible .ant-pagination-item-link,
.dark .ant-pagination-next:focus-visible .ant-pagination-item-link,
.dark .ant-pagination-prev:hover .ant-pagination-item-link,
.dark .ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}

.dark .ant-pagination-disabled .ant-pagination-item-link,
.dark .ant-pagination-disabled:hover .ant-pagination-item-link,
.dark .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
}

.dark .ant-pagination-options-quick-jumper input {
  color: rgba(255, 255, 255, 0.85);
  border: 1px solid #434343;
}

.dark .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-input-textarea{
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-pagination-options-quick-jumper input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
}

.dark .ant-pagination-options-quick-jumper input:focus,
.dark .ant-pagination-options-quick-jumper input-focused {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
}

.dark .ant-pagination-options-quick-jumper input-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #434343;
}

.dark .ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #434343;
}

.dark .ant-pagination-simple .ant-pagination-simple-pager input {
  border: 1px solid #434343;
}

.dark .ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}

.dark .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: rgba(255, 255, 255, 0.25);
}

.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #000;
}

.dark .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(255, 255, 255, 0.3);
}


/* BUTTON */

.dark .ant-btn {
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(255, 255, 255, 0.85);
  border-color: #434343;
  background: transparent;
}

.dark .ant-btn:hover,
.dark .ant-btn:focus {
  color: #fff;
  border-color: #177ddc;
  background: transparent;
}

.dark .ant-btn:hover>a:only-child,
.dark .ant-btn:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn:hover>a:only-child::after,
.dark .ant-btn:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn:active {
  color: #388ed3;
  border-color: #388ed3;
  background: transparent;
}

.dark .ant-btn:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn[disabled],
.dark .ant-btn[disabled]:hover,
.dark .ant-btn[disabled]:focus,
.dark .ant-btn[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn[disabled]>a:only-child,
.dark .ant-btn[disabled]:hover>a:only-child,
.dark .ant-btn[disabled]:focus>a:only-child,
.dark .ant-btn[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn[disabled]>a:only-child::after,
.dark .ant-btn[disabled]:hover>a:only-child::after,
.dark .ant-btn[disabled]:focus>a:only-child::after,
.dark .ant-btn[disabled]:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn:hover,
.dark .ant-btn:focus,
.dark .ant-btn:active {
  background: transparent;
}

.dark .ant-btn-primary {
  color: #fff;
  border-color: #177ddc;
  background: #177ddc;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.dark .ant-btn-primary>a:only-child {
  color: currentColor;
}

.dark .ant-btn-primary>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-primary:hover,
.dark .ant-btn-primary:focus {
  color: #fff;
  border-color: #40a9ff;
  background: #40a9ff;
}

.dark .ant-btn-primary:hover>a:only-child,
.dark .ant-btn-primary:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-primary:hover>a:only-child::after,
.dark .ant-btn-primary:focus>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-primary:active {
  color: #fff;
  border-color: #3c9be8;
  background: #3c9be8;
}

.dark .ant-btn-primary:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-primary:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-primary[disabled],
.dark .ant-btn-primary[disabled]:hover,
.dark .ant-btn-primary[disabled]:focus,
.dark .ant-btn-primary[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-primary[disabled]>a:only-child,
.dark .ant-btn-primary[disabled]:hover>a:only-child,
.dark .ant-btn-primary[disabled]:focus>a:only-child,
.dark .ant-btn-primary[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-primary[disabled]>a:only-child::after,
.dark .ant-btn-primary[disabled]:hover>a:only-child::after,
.dark .ant-btn-primary[disabled]:focus>a:only-child::after,
.dark .ant-btn-primary[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff;
}

.dark .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #434343;
}

.dark .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}

.dark .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #434343;
}

.dark .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.dark .ant-btn-group .ant-btn-primary+.ant-btn-primary {
  border-left-color: #40a9ff;
}

.dark .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.dark .ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
  border-left-color: #434343;
}

.dark .ant-btn-ghost {
  color: rgba(255, 255, 255, 0.85);
  border-color: #434343;
  background: transparent;
}

.dark .ant-btn-ghost>a:only-child {
  color: currentColor;
}

.dark .ant-btn-ghost>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-ghost:hover,
.dark .ant-btn-ghost:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.dark .ant-btn-ghost:hover>a:only-child,
.dark .ant-btn-ghost:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-ghost:hover>a:only-child::after,
.dark .ant-btn-ghost:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-ghost:active {
  color: #388ed3;
  border-color: #388ed3;
  background: transparent;
}

.dark .ant-btn-ghost:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-ghost:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-ghost[disabled],
.dark .ant-btn-ghost[disabled]:hover,
.dark .ant-btn-ghost[disabled]:focus,
.dark .ant-btn-ghost[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-ghost[disabled]>a:only-child,
.dark .ant-btn-ghost[disabled]:hover>a:only-child,
.dark .ant-btn-ghost[disabled]:focus>a:only-child,
.dark .ant-btn-ghost[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-ghost[disabled]>a:only-child::after,
.dark .ant-btn-ghost[disabled]:hover>a:only-child::after,
.dark .ant-btn-ghost[disabled]:focus>a:only-child::after,
.dark .ant-btn-ghost[disabled]:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dashed {
  color: rgba(255, 255, 255, 0.85);
  border-color: #434343;
  background: transparent;
  border-style: dashed;
}

.dark .ant-btn-dashed>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dashed>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dashed:hover,
.dark .ant-btn-dashed:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.dark .ant-btn-dashed:hover>a:only-child,
.dark .ant-btn-dashed:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dashed:hover>a:only-child::after,
.dark .ant-btn-dashed:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dashed:active {
  color: #388ed3;
  border-color: #388ed3;
  background: transparent;
}

.dark .ant-btn-dashed:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dashed:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dashed[disabled],
.dark .ant-btn-dashed[disabled]:hover,
.dark .ant-btn-dashed[disabled]:focus,
.dark .ant-btn-dashed[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-dashed[disabled]>a:only-child,
.dark .ant-btn-dashed[disabled]:hover>a:only-child,
.dark .ant-btn-dashed[disabled]:focus>a:only-child,
.dark .ant-btn-dashed[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dashed[disabled]>a:only-child::after,
.dark .ant-btn-dashed[disabled]:hover>a:only-child::after,
.dark .ant-btn-dashed[disabled]:focus>a:only-child::after,
.dark .ant-btn-dashed[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-danger {
  color: #fff;
  border-color: #a61d24;
  background: #a61d24;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.dark .ant-btn-danger>a:only-child {
  color: currentColor;
}

.dark .ant-btn-danger>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-danger:hover,
.dark .ant-btn-danger:focus {
  color: #fff;
  border-color: #800f19;
  background: #800f19;
}

.dark .ant-btn-danger:hover>a:only-child,
.dark .ant-btn-danger:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-danger:hover>a:only-child::after,
.dark .ant-btn-danger:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-danger:active {
  color: #fff;
  border-color: #b33b3d;
  background: #b33b3d;
}

.dark .ant-btn-danger:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-danger:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-danger[disabled],
.dark .ant-btn-danger[disabled]:hover,
.dark .ant-btn-danger[disabled]:focus,
.dark .ant-btn-danger[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-danger[disabled]>a:only-child,
.dark .ant-btn-danger[disabled]:hover>a:only-child,
.dark .ant-btn-danger[disabled]:focus>a:only-child,
.dark .ant-btn-danger[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-danger[disabled]>a:only-child::after,
.dark .ant-btn-danger[disabled]:hover>a:only-child::after,
.dark .ant-btn-danger[disabled]:focus>a:only-child::after,
.dark .ant-btn-danger[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-link {
  color: #177ddc;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.dark .ant-btn-link>a:only-child {
  color: currentColor;
}

.dark .ant-btn-link>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-link:hover,
.dark .ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.dark .ant-btn-link:hover>a:only-child,
.dark .ant-btn-link:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-link:hover>a:only-child::after,
.dark .ant-btn-link:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-link:active {
  color: #388ed3;
  border-color: #388ed3;
  background: transparent;
}

.dark .ant-btn-link:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-link:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-link[disabled],
.dark .ant-btn-link[disabled]:hover,
.dark .ant-btn-link[disabled]:focus,
.dark .ant-btn-link[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-link[disabled]>a:only-child,
.dark .ant-btn-link[disabled]:hover>a:only-child,
.dark .ant-btn-link[disabled]:focus>a:only-child,
.dark .ant-btn-link[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-link[disabled]>a:only-child::after,
.dark .ant-btn-link[disabled]:hover>a:only-child::after,
.dark .ant-btn-link[disabled]:focus>a:only-child::after,
.dark .ant-btn-link[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-link:hover {
  background: transparent;
}

.dark .ant-btn-link:hover,
.dark .ant-btn-link:focus,
.dark .ant-btn-link:active {
  border-color: transparent;
}

.dark .ant-btn-link[disabled],
.dark .ant-btn-link[disabled]:hover,
.dark .ant-btn-link[disabled]:focus,
.dark .ant-btn-link[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-link[disabled]>a:only-child,
.dark .ant-btn-link[disabled]:hover>a:only-child,
.dark .ant-btn-link[disabled]:focus>a:only-child,
.dark .ant-btn-link[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-link[disabled]>a:only-child::after,
.dark .ant-btn-link[disabled]:hover>a:only-child::after,
.dark .ant-btn-link[disabled]:focus>a:only-child::after,
.dark .ant-btn-link[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-text {
  color: rgba(255, 255, 255, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.dark .ant-btn-text>a:only-child {
  color: currentColor;
}

.dark .ant-btn-text>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-text:hover,
.dark .ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.dark .ant-btn-text:hover>a:only-child,
.dark .ant-btn-text:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-text:hover>a:only-child::after,
.dark .ant-btn-text:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-text:active {
  color: #388ed3;
  border-color: #388ed3;
  background: transparent;
}

.dark .ant-btn-text:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-text:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-text[disabled],
.dark .ant-btn-text[disabled]:hover,
.dark .ant-btn-text[disabled]:focus,
.dark .ant-btn-text[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-text[disabled]>a:only-child,
.dark .ant-btn-text[disabled]:hover>a:only-child,
.dark .ant-btn-text[disabled]:focus>a:only-child,
.dark .ant-btn-text[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-text[disabled]>a:only-child::after,
.dark .ant-btn-text[disabled]:hover>a:only-child::after,
.dark .ant-btn-text[disabled]:focus>a:only-child::after,
.dark .ant-btn-text[disabled]:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-text:hover,
.dark .ant-btn-text:focus {
  color: rgba(255, 255, 255, 0.85);
  background: rgba(255, 255, 255, 0.03);
  border-color: transparent;
}

.dark .ant-btn-text:active {
  color: rgba(255, 255, 255, 0.85);
  background: rgba(255, 255, 255, 0.04);
  border-color: transparent;
}

.dark .ant-btn-text[disabled],
.dark .ant-btn-text[disabled]:hover,
.dark .ant-btn-text[disabled]:focus,
.dark .ant-btn-text[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-text[disabled]>a:only-child,
.dark .ant-btn-text[disabled]:hover>a:only-child,
.dark .ant-btn-text[disabled]:focus>a:only-child,
.dark .ant-btn-text[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-text[disabled]>a:only-child::after,
.dark .ant-btn-text[disabled]:hover>a:only-child::after,
.dark .ant-btn-text[disabled]:focus>a:only-child::after,
.dark .ant-btn-text[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous {
  color: #a61d24;
  border-color: #a61d24;
  background: transparent;
}

.dark .ant-btn-dangerous>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous:hover,
.dark .ant-btn-dangerous:focus {
  color: #800f19;
  border-color: #800f19;
  background: transparent;
}

.dark .ant-btn-dangerous:hover>a:only-child,
.dark .ant-btn-dangerous:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous:hover>a:only-child::after,
.dark .ant-btn-dangerous:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous:active {
  color: #b33b3d;
  border-color: #b33b3d;
  background: transparent;
}

.dark .ant-btn-dangerous:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous[disabled],
.dark .ant-btn-dangerous[disabled]:hover,
.dark .ant-btn-dangerous[disabled]:focus,
.dark .ant-btn-dangerous[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-dangerous[disabled]>a:only-child,
.dark .ant-btn-dangerous[disabled]:hover>a:only-child,
.dark .ant-btn-dangerous[disabled]:focus>a:only-child,
.dark .ant-btn-dangerous[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous[disabled]>a:only-child::after,
.dark .ant-btn-dangerous[disabled]:hover>a:only-child::after,
.dark .ant-btn-dangerous[disabled]:focus>a:only-child::after,
.dark .ant-btn-dangerous[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #a61d24;
  background: #a61d24;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.dark .ant-btn-dangerous.ant-btn-primary>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-primary>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-primary:hover,
.dark .ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #800f19;
  background: #800f19;
}

.dark .ant-btn-dangerous.ant-btn-primary:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-primary:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-primary:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-primary:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #b33b3d;
  background: #b33b3d;
}

.dark .ant-btn-dangerous.ant-btn-primary:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-primary:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-primary[disabled],
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-link {
  color: #a61d24;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.dark .ant-btn-dangerous.ant-btn-link>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-link>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-link:hover,
.dark .ant-btn-dangerous.ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-link:active {
  color: #388ed3;
  border-color: #388ed3;
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-link[disabled],
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-link:hover,
.dark .ant-btn-dangerous.ant-btn-link:focus {
  color: #800f19;
  border-color: transparent;
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-link:active {
  color: #b33b3d;
  border-color: transparent;
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-link[disabled],
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-text {
  color: #a61d24;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.dark .ant-btn-dangerous.ant-btn-text>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-text>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-text:hover,
.dark .ant-btn-dangerous.ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-text:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-text:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-text:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text:focus>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-text:active {
  color: #388ed3;
  border-color: #388ed3;
  background: transparent;
}

.dark .ant-btn-dangerous.ant-btn-text:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-text:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-text[disabled],
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-dangerous.ant-btn-text[disabled]>a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-text[disabled]>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-text:hover,
.dark .ant-btn-dangerous.ant-btn-text:focus {
  color: #800f19;
  border-color: transparent;
  background: rgba(255, 255, 255, 0.03);
}

.dark .ant-btn-dangerous.ant-btn-text:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-text:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-text:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text:focus>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-text:active {
  color: #b33b3d;
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
}

.dark .ant-btn-dangerous.ant-btn-text:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-text:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn-dangerous.ant-btn-text[disabled],
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-dangerous.ant-btn-text[disabled]>a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-dangerous.ant-btn-text[disabled]>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child::after,
.dark .ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child::after {
  background: transparent;
  content: '';
}

.dark .ant-btn::before {
  background: #141414;
}

.dark .ant-btn.ant-btn-background-ghost {
  color: rgba(255, 255, 255, 0.85);
  border-color: rgba(255, 255, 255, 0.25);
}

.dark .ant-btn.ant-btn-background-ghost,
.dark .ant-btn.ant-btn-background-ghost:hover,
.dark .ant-btn.ant-btn-background-ghost:active,
.dark .ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-primary {
  color: #177ddc;
  border-color: #177ddc;
  text-shadow: none;
}

.dark .ant-btn-background-ghost.ant-btn-primary>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-primary>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-primary:hover,
.dark .ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}

.dark .ant-btn-background-ghost.ant-btn-primary:hover>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-primary:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-primary:hover>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-primary:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-primary:active {
  color: #3c9be8;
  border-color: #3c9be8;
}

.dark .ant-btn-background-ghost.ant-btn-primary:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-primary:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-primary[disabled],
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-danger {
  color: #a61d24;
  border-color: #a61d24;
  text-shadow: none;
}

.dark .ant-btn-background-ghost.ant-btn-danger>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-danger>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-danger:hover,
.dark .ant-btn-background-ghost.ant-btn-danger:focus {
  color: #800f19;
  border-color: #800f19;
}

.dark .ant-btn-background-ghost.ant-btn-danger:hover>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-danger:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-danger:hover>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-danger:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-danger:active {
  color: #b33b3d;
  border-color: #b33b3d;
}

.dark .ant-btn-background-ghost.ant-btn-danger:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-danger:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-danger[disabled],
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous {
  color: #a61d24;
  border-color: #a61d24;
  text-shadow: none;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous:hover,
.dark .ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #800f19;
  border-color: #800f19;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #b33b3d;
  border-color: #b33b3d;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled],
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #a61d24;
  border-color: transparent;
  text-shadow: none;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #800f19;
  border-color: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #b33b3d;
  border-color: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(255, 255, 255, 0.3);
  border-color: #434343;
  background: rgba(255, 255, 255, 0.08);
  text-shadow: none;
  box-shadow: none;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child {
  color: currentColor;
}

.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child::after,
.dark .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child::after {
  background: transparent;
}

.dark .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.dark .ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary {
  border-right-color: #40a9ff;
  border-left-color: #434343;
}

.dark .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.dark .ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled] {
  border-right-color: #434343;
  border-left-color: #40a9ff;
}

/* RADIO BUTTON */

.dark .ant-radio-group {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-radio-wrapper {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-radio {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-radio-wrapper:hover .ant-radio,
.dark .ant-radio:hover .ant-radio-inner,
.dark .ant-radio-input:focus+.ant-radio-inner {
  border-color: #177ddc;
}

.dark .ant-radio-input:focus+.ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(23, 125, 220, 0.08);
}

.dark .ant-radio-checked::after {
  border: 1px solid #177ddc;
}

.dark .ant-radio-inner {
  background-color: transparent;
  border-color: #434343;
}

.dark .ant-radio-inner::after {
  background-color: #177ddc;
}

.dark .ant-radio-checked .ant-radio-inner {
  border-color: #177ddc;
}

.dark .ant-radio-disabled .ant-radio-inner {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343 !important;
}

.dark .ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark .ant-radio-disabled+span {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-radio-button-wrapper {
  color: rgba(255, 255, 255, 0.85);
  background: transparent;
  border: 1px solid #434343;
}

.dark .ant-radio-button-wrapper a {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #434343;
}

.dark .ant-radio-button-wrapper:first-child {
  border-left: 1px solid #434343;
}

.dark .ant-radio-button-wrapper:hover {
  color: #fff;

}

.dark .ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(23, 125, 220, 0.08);
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: rgba(255, 255, 255, .8);
  background: transparent;
  border-color: #177ddc;
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #177ddc;
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #177ddc;
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: rgba(255, 255, 255, .8);
  border-color: #177ddc;
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #177ddc;
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #388ed3;
  border-color: #388ed3;
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #388ed3;
}

.dark .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(23, 125, 220, 0.08);
}

.dark .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #177ddc;
  border-color: #177ddc;
}

.dark .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #177ddc;
  border-color: #177ddc;
}

.dark .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #388ed3;
  border-color: #388ed3;
}

.dark .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(23, 125, 220, 0.08);
}

.dark .ant-radio-button-wrapper-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
  cursor: not-allowed;
}

.dark .ant-radio-button-wrapper-disabled:first-child,
.dark .ant-radio-button-wrapper-disabled:hover {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #434343;
}

.dark .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #434343;
  box-shadow: none;
}

.dark .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #434343;
}

.dark .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #177ddc;
}

.dark .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}

.dark .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #434343;
}

.dark .ant-form-item .ant-upload {
  background: transparent;
  color: rgba(255, 255, 255, .8);
}

.dark .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #333;
}

.dark .ant-upload.ant-upload-select-picture-card:hover {
  border: 1px dashed #555;
}

/* TAGS */

.dark .details-box div:nth-of-type(odd) {
  background-color:#383838;
}
   
.dark .details-box div:nth-of-type(even) {
  background-color:#333;
}




.dark .ant-tag {
  color: rgba(255, 255, 255, .8) !important;
  cursor: pointer;
}

.dark .ant-tag:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.dark .filterTag {
  background-color: #252526;
}

.dark .ant-tag-red {
  color: #e84749;
  background: #252526;
  border-color: #8a0008;
}

.dark .ant-tag-red {
  color: #e84749;
  border-color: #58181c;
}

.dark .ant-tag-green {
  color: #6abe39;
  background: #162312;
  border-color: #274916;
}


.dark .anticon-close svg {
  fill: rgba(255, 255, 255, 0.45);
}

.dark .anticon-close2 svg {
  fill: rgba(255, 255, 255, 1);
}


/* ALERT */

.dark .ant-alert {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-alert-success {
  background-color: #162312;
  border: 1px solid #274916;
}

.dark .ant-alert-success .ant-alert-icon {
  color: #49aa19;
}

.dark .ant-alert-info {
  background-color: #111b26;
  border: 1px solid #153450;
}

.dark .ant-alert-info .ant-alert-icon {
  color: #177ddc;
}

.dark .ant-alert-warning {
  background-color: #2b2111;
  border: 1px solid #594214;
}

.dark .ant-alert-warning .ant-alert-icon {
  color: #d89614;
}

.dark .ant-alert-error {
  background-color: #2a1215;
  border: 1px solid #58181c;
}

.dark .ant-alert-error .ant-alert-icon {
  color: #a61d24;
}

.dark .ant-alert-close-icon {
  background-color: transparent;
}

.dark .ant-alert-close-icon .anticon-close {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-alert-close-icon .anticon-close:hover {
  color: rgba(255, 255, 255, 0.75);
}

.dark .ant-alert-close-text {
  color: rgba(255, 255, 255, 0.45);
  transition: color 0.3s;
}

.dark .ant-alert-close-text:hover {
  color: rgba(255, 255, 255, 0.75);
}

.dark .ant-alert-with-description .ant-alert-message {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-alert-message {
  color: rgba(255, 255, 255, 0.85);
}

/* NUMBER */

.dark .ant-input-number {
  color: rgba(255, 255, 255, 0.85);
  background-color: #252526;
  border: 1px solid #333;
}

.dark .ant-input-number:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.ant-input-number::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-input-number:hover {
  border-color: #40a9ff;
}

.dark .ant-input-number:focus,
.dark .ant-input-number-focused {
  border-color: #177ddc;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
}

.dark .ant-input-number-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-input-number-disabled:hover {
  border-color: #434343;
}

.dark .ant-input-number[disabled] {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.dark .ant-input-number[disabled]:hover {
  border-color: #434343;
  border-right-width: 1px !important;
}

.dark .ant-input-number-borderless,
.dark .ant-input-number-borderless:hover,
.dark .ant-input-number-borderless:focus,
.dark .ant-input-number-borderless-focused,
.dark .ant-input-number-borderless-disabled,
.dark .ant-input-number-borderless[disabled] {
  background-color: transparent;
}

.dark .ant-input-number-handler {
  color: rgba(255, 255, 255, 0.45);
  border-left: 1px solid #434343;
}

.dark .ant-input-number-handler:active {
  background: rgba(255, 255, 255, 0.08);
}

.dark .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.dark .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #40a9ff;
}

.dark .ant-input-number-handler-up-inner,
.dark .ant-input-number-handler-down-inner {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-input-number:hover {
  border-color: #40a9ff;
}

.dark .ant-input-number-focused {
  border-color: #177ddc;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
}

.dark .ant-input-number-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343;
}

.dark .ant-input-number-disabled:hover {
  border-color: #434343;
}

.dark .ant-input-number-input {
  background-color: transparent;
}

.dark .ant-input-number-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-input-number-input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-input-number-handler-wrap {
  background: #141414;
}

.dark .ant-input-number-handler-down {
  border-top: 1px solid #434343;
}

.dark .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.dark .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-input-number-out-of-range input {
  color: #a61d24;
}

.dark .ant-input-number-rtl .ant-input-number-handler-wrap {
  border-right: 1px solid #434343;
}

.dark .ant-input-number-handler-wrap {
  border-left: none;
}

/* SPIN */


.dark .grid-lines {
  background-size: 30px 30px;

  background-image: linear-gradient(to bottom, 
  #444, 1px, 
  transparent 1px);

}

.dark .grid-line{
  background-color: #444;
}

.dark .ant-spin {
  color: rgba(255, 255, 255, 0.85);


}

.dark .ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
  text-shadow: 0 1px 2px #141414;
}

.dark .ant-spin-container::after {
  background: #262626;
}

.ant-list-split.ant-list-something-after-last-item .ant-spin-container>.ant-list-items>.ant-list-item:last-child {
  border-bottom: 1px solid #303030;
}

.dark .ant-spin-tip {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-spin-dot-item {
  background-color: #177ddc;
}

/* POPOVER */

.dark .ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow,
.dark .ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow,
.dark .ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
  border-top-color: #1f1f1f;
  border-left-color: #1f1f1f;
}


.dark .ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow,
.dark .ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow,
.dark .ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
  border-top-color: #1f1f1f;
  border-left-color: #1f1f1f;
}

.dark .ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow,
.dark .ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow,
.dark .ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
  border-bottom-color: #1f1f1f;
  border-right-color: #1f1f1f;
}





.dark .ant-popover {
  color: rgba(255, 255, 255, 0.85) !important;
}

.dark .ant-popover::after {
  background: rgba(255, 255, 255, 0.01);
}

.dark .ant-popover-inner {
  background-color: #1f1f1f;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.45) \9;
}

.dark .ant-popover-title {
  color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid #303030;
}

.dark .bottom-block-border{
  border-bottom: 2px solid #303030;
}

.dark .ant-popover-inner-content {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-popover-message {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-popover-message>.anticon {
  color: #d89614;
}

.dark .ant-popover-arrow-content {
  background-color: #1f1f1f;
}

.dark .ant-popover-placement-top .ant-popover-arrow-content,
.dark .ant-popover-placement-topLeft .ant-popover-arrow-content,
.dark .ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.dark .ant-popover-placement-right .ant-popover-arrow-content,
.dark .ant-popover-placement-rightTop .ant-popover-arrow-content,
.dark .ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}

.dark .ant-popover-placement-bottom .ant-popover-arrow-content,
.dark .ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.dark .ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}

.dark .ant-popover-placement-left .ant-popover-arrow-content,
.dark .ant-popover-placement-leftTop .ant-popover-arrow-content,
.dark .ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}

.dark .ant-popover-pink .ant-popover-inner {
  background-color: #cb2b83;
}

.dark .ant-popover-pink .ant-popover-arrow-content {
  background-color: #cb2b83;
}

.dark .ant-popover-magenta .ant-popover-inner {
  background-color: #cb2b83;
}

.dark .ant-popover-magenta .ant-popover-arrow-content {
  background-color: #cb2b83;
}

.dark .ant-popover-red .ant-popover-inner {
  background-color: #d32029;
}

.dark .ant-popover-red .ant-popover-arrow-content {
  background-color: #d32029;
}

.dark .ant-popover-volcano .ant-popover-inner {
  background-color: #d84a1b;
}

.dark .ant-popover-volcano .ant-popover-arrow-content {
  background-color: #d84a1b;
}

.dark .ant-popover-orange .ant-popover-inner {
  background-color: #d87a16;
}

.dark .ant-popover-orange .ant-popover-arrow-content {
  background-color: #d87a16;
}

.dark .ant-popover-yellow .ant-popover-inner {
  background-color: #d8bd14;
}

.dark .ant-popover-yellow .ant-popover-arrow-content {
  background-color: #d8bd14;
}

.dark .ant-popover-gold .ant-popover-inner {
  background-color: #d89614;
}

.dark .ant-popover-gold .ant-popover-arrow-content {
  background-color: #d89614;
}

.dark .ant-popover-cyan .ant-popover-inner {
  background-color: #13a8a8;
}

.dark .ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13a8a8;
}

.dark .ant-popover-lime .ant-popover-inner {
  background-color: #8bbb11;
}

.dark .ant-popover-lime .ant-popover-arrow-content {
  background-color: #8bbb11;
}

.dark .ant-popover-green .ant-popover-inner {
  background-color: #49aa19;
}

.dark .ant-popover-green .ant-popover-arrow-content {
  background-color: #49aa19;
}

.dark .ant-popover-blue .ant-popover-inner {
  background-color: #177ddc;
}

.dark .ant-popover-blue .ant-popover-arrow-content {
  background-color: #177ddc;
}

.dark .ant-popover-geekblue .ant-popover-inner {
  background-color: #2b4acb;
}

.dark .ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2b4acb;
}

.dark .ant-popover-purple .ant-popover-inner {
  background-color: #642ab5;
}

.dark .ant-popover-purple .ant-popover-arrow-content {
  background-color: #642ab5;
}

/* SELECT */

.dark .ant-select-auto-complete {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-select-auto-complete .ant-select-clear {
  right: 13px;
}

.dark .ant-select-single.ant-select-open .ant-select-selection-item {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #141414;
}

.dark .ant-select-multiple .ant-select-selection-item {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid #303030;
}

.dark .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #595959;
  border-color: #1f1f1f;
}

.dark .ant-select-multiple .ant-select-selection-item-remove {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(255, 255, 255, 0.75);
}

.dark .ant-select {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background: rgba(255, 255, 255, 0.08);
}

.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #434343;
}

.dark .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #177ddc;
  box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
}

.dark .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
}

.dark .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #141414;
}

.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  background: transparent;
}

.dark .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #40a9ff;
}

.dark .ant-select-arrow {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-select-clear {
  color: rgba(255, 255, 255, 0.3);
  background: none;
  /* margin-top: -2px;  */
}

.dark .ant-select-clear:hover {
  color: rgba(255, 255, 255, 0.45);

}

.dark .ant-select-dropdown {
  color: rgba(255, 255, 255, 0.85);
  background-color: #1f1f1f;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
}

.dark .ant-select-dropdown-empty {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-select-item-empty {
  color: rgba(255, 255, 255, 0.85);
  /* color: rgba(255, 255, 255, 0.3); */
}

.dark .ant-select-item {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-select-item-group {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(255, 255, 255, 0.08);
}

.dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85);
  background-color: #177ddc;
}

.dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #177ddc;
}

.dark .ant-select-item-option-disabled {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #141414;
}

.dark .ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

/* DROPDOWN */

.dark .ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow,
.dark .ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow,
.dark .ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow {
  border-bottom-color: #1f1f1f;
  border-left-color: #1f1f1f;
}

.dark .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #e14c54;
}

.dark .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #a61d24;
}

.dark .ant-dropdown {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-dropdown-arrow {
  background: transparent;
}

.dark .ant-dropdown-placement-topCenter>.ant-dropdown-arrow,
.dark .ant-dropdown-placement-topLeft>.ant-dropdown-arrow,
.dark .ant-dropdown-placement-topRight>.ant-dropdown-arrow {
  border-top-color: transparent;
  border-right-color: #1f1f1f;
  border-bottom-color: #1f1f1f;
  border-left-color: transparent;
}

.dark .ant-dropdown-placement-bottomCenter>.ant-dropdown-arrow,
.dark .ant-dropdown-placement-bottomLeft>.ant-dropdown-arrow,
.dark .ant-dropdown-placement-bottomRight>.ant-dropdown-arrow {
  border-top-color: #1f1f1f;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #1f1f1f;
}

.dark .ant-dropdown-menu {
  background-color: #1f1f1f;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48);
}

.dark .ant-dropdown-menu-item-group-title {
  color: #bbb;
}

.dark .ant-dropdown-menu-submenu-popup {
  background: transparent;
  box-shadow: none;
}

.dark .ant-dropdown-menu-item,
.dark .ant-dropdown-menu-submenu-title {
  color: #bbb;
}

.dark .ant-dropdown-menu-item-selected,
.dark .ant-dropdown-menu-submenu-title-selected {
  color: #177ddc;
  background-color: #111b26;
}

.dark .ant-dropdown-menu-item:hover,
.dark .ant-dropdown-menu-submenu-title:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.dark .ant-dropdown-menu-item-disabled,
.dark .ant-dropdown-menu-submenu-title-disabled {
  color: rgba(255, 255, 255, 0.3);
}

.dark .ant-dropdown-menu-item-disabled:hover,
.dark .ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}

.dark .ant-dropdown-menu-item-divider,
.dark .ant-dropdown-menu-submenu-title-divider {
  background-color: #303030;
}

.dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.dark .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}

.dark .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #177ddc;
}

.dark .ant-dropdown-menu-dark,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #1f1f1f;
}

.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item>a,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item>.anticon+span>a {
  color: rgba(255, 255, 255, 0.65);
}

.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item>a .ant-dropdown-menu-submenu-arrow::after,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item>.anticon+span>a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}

.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item>a:hover,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item>.anticon+span>a:hover {
  color: #fff;
  background: transparent;
}

.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.dark .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected>a {
  color: #fff;
  background: #177ddc;
}

/* RESULT */


.dark .ant-result-success .ant-result-icon>.anticon {
  color: #49aa19;
}

.dark .ant-result-error .ant-result-icon>.anticon {
  color: #a61d24;
}

.dark .ant-result-info .ant-result-icon>.anticon {
  color: #177ddc;
}

.dark .ant-result-warning .ant-result-icon>.anticon {
  color: #d89614;
}

.dark .ant-result-title {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-result-subtitle {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-result-content {
  background-color: rgba(255, 255, 255, 0.04);
}

.dark p strong{
  color: #fff;
}


/* DRAWER */


.dark .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.32), 9px 0 28px 0 rgba(0, 0, 0, 0.0), 12px 0 48px 16px rgba(0, 0, 0, 0.0);
}

.dark .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.dark .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.32), 0 9px 28px 0 rgba(0, 0, 0, 0.2), 0 12px 48px 16px rgba(0, 0, 0, 0.12);
}

.dark .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.32), 0 -9px 28px 0 rgba(0, 0, 0, 0.2), 0 -12px 48px 16px rgba(0, 0, 0, 0.12);
}

.dark .ant-drawer-title {
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-drawer-content {
  background-color: #1f1f1f;
}

.dark .ant-drawer-close {
  color: rgba(255, 255, 255, 0.45);
  background: transparent;
}

.dark .ant-drawer-close:focus,
.dark .ant-drawer-close:hover {
  color: rgba(255, 255, 255, 0.75);
}

.dark .ant-drawer-header {
  color: rgba(255, 255, 255, 0.85);
  background: #1f1f1f;
  border-bottom: 1px solid #303030;
}

.dark .ant-drawer-header-no-title {
  color: rgba(255, 255, 255, 0.85);
  background: #1f1f1f;
}

.dark .ant-drawer-footer {
  border-top: 1px solid #303030;
}

.dark .ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.45);
}

.dark .ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2);
}

.dark .ant-drawer .ant-picker-clear {
  background: #1f1f1f;
}

.dark .ant-drawer .ant-picker-clear,
.dark .ant-drawer .ant-slider-handle,
.dark .ant-drawer .ant-anchor-wrapper,
.dark .ant-drawer .ant-collapse-content,
.dark .ant-drawer .ant-timeline-item-head,
.dark .ant-drawer .ant-card {
  background-color: #1f1f1f;
}

.dark .ant-drawer .ant-transfer-list-header {
  background: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.dark .ant-drawer tr.ant-table-expanded-row>td,
.dark .ant-drawer tr.ant-table-expanded-row:hover>td {
  background: #272727;
}

.dark .ant-drawer .ant-table.ant-table-small thead>tr>th {
  background-color: #1f1f1f;
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table {
  background-color: #1f1f1f;
}

.dark .ant-drawer .ant-table .ant-table-row-expand-icon {
  border: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table tfoot>tr>th,
.dark .ant-drawer .ant-table tfoot>tr>td {
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table thead>tr>th {
  background-color: #272727;
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table tbody>tr>td {
  border-bottom: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table tbody>tr>td.ant-table-cell-fix-left,
.dark .ant-drawer .ant-table tbody>tr>td.ant-table-cell-fix-right {
  background-color: #1f1f1f;
}

.dark .ant-drawer .ant-table tbody>tr.ant-table-row:hover>td {
  background: #303030;
}

.dark .ant-drawer .ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table.ant-table-bordered thead>tr>th,
.dark .ant-drawer .ant-table.ant-table-bordered tbody>tr>td,
.dark .ant-drawer .ant-table.ant-table-bordered tfoot>tr>th,
.dark .ant-drawer .ant-table.ant-table-bordered tfoot>tr>td {
  border-right: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table.ant-table-bordered table thead>tr:not(:last-child)>th {
  border-bottom: 1px solid #303030;
}

.dark .ant-drawer .ant-table.ant-table-bordered .ant-table-container {
  border: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table.ant-table-bordered .ant-table-expanded-row-fixed::after {
  border-right: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #3a3a3a;
}

.dark .ant-drawer .ant-table .ant-table-filter-trigger-container-open {
  background-color: #525252;
}

.dark .ant-drawer .ant-picker-calendar-full {
  background-color: #1f1f1f;
}

.dark .ant-drawer .ant-picker-calendar-full .ant-picker-panel {
  background-color: #1f1f1f;
}

.dark .ant-drawer .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-top: 2px solid #3a3a3a;
}

.dark .ant-drawer .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
}

.dark .ant-drawer .ant-badge-count {
  box-shadow: 0 0 0 1px #1f1f1f;
}

.dark .ant-drawer .ant-tree-show-line .ant-tree-switcher {
  background: #1f1f1f;
}


.dark .rep {
  background-color: #333333 !important;
  border: 1px solid #444 !important;
}

/* STATS */

.dark .ant-statistic {
  background-color: #2f2f2f;
  color: rgba(255, 255, 255, 0.85);
}

.dark .ant-statistic-title {
  color: rgba(255, 255, 255, 0.45);
}

.dark .ant-statistic-content {
  color: rgba(255, 255, 255, 0.85);
}

/* TREE */

.dark .ant-select-tree-checkbox {
  color: rgba(255, 255, 255, 0.85);
}
.dark .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.dark .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.dark .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #177ddc;
}
.dark .ant-select-tree-checkbox-checked::after {
  border: 1px solid #177ddc;
}
.dark .ant-select-tree-checkbox-inner {
  background-color: transparent;
  border: 1px solid #434343;
}
.dark .ant-select-tree-checkbox-inner::after {
  border: 2px solid #fff;
}
.dark .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border: 2px solid #fff;
}
.dark .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #177ddc;
  border-color: #177ddc;
}
.dark .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: #434343 !important;
}
.dark .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-select-tree-checkbox-disabled + span {
  color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-tree-checkbox-wrapper {
  color: rgba(255, 255, 255, 0.85);
}
.dark .ant-select-tree-checkbox-group {
  color: rgba(255, 255, 255, 0.85);
}
.dark .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: transparent;
  border-color: #434343;
}
.dark .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: #177ddc;
}
.dark .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-tree {
  color: rgba(255, 255, 255, 0.85);
  background: transparent;
}
.dark .ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #111b26;
}
.dark .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(255, 255, 255, 0.3);
}
.dark .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.dark .ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: rgba(255, 255, 255, 0.08);
}
.dark .ant-select-tree-switcher-loading-icon {
  color: #177ddc;
}
.dark .ant-select-tree-switcher-leaf-line::before {
  border-left: 1px solid #d9d9d9;
}
.dark .ant-select-tree-switcher-leaf-line::after {
  border-bottom: 1px solid #d9d9d9;
}
.dark .ant-select-tree .ant-select-tree-node-content-wrapper {
  background: transparent;
}
.dark .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #11263c;
}
.dark .ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.dark .ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  background-color: #177ddc;
}
.dark .ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  background-color: transparent;
  border: 2px solid #177ddc;
}
.dark .ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #177ddc;
}
.dark .ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  border-right: 1px solid #434343;
}
.dark .ant-select-tree-show-line .ant-select-tree-switcher {
  background: #141414;
}

/* UPLOAD */

.dark .ant-upload {
  color: rgba(255, 255, 255, 0.85);
}
.dark .ant-upload.ant-upload-select-picture-card {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px dashed #434343;
}
.dark .ant-upload.ant-upload-select-picture-card:hover {
  border-color: #177ddc;
}
.dark .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #434343;
}
.dark .ant-upload.ant-upload-drag {
  background: rgba(255, 255, 255, 0.04);
  border: 1px dashed #434343;
}
.dark .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #388ed3;
}
.dark .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}
.dark .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
}
.dark .ant-upload.ant-upload-drag p.ant-upload-text {
  color: rgba(255, 255, 255, 0.85);
}
.dark .ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(255, 255, 255, 0.45);
}
.dark .ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(255, 255, 255, 0.3);
}
.dark .ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(255, 255, 255, 0.45);
}
.dark .ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(255, 255, 255, 0.45);
}
.dark .ant-upload-list {
  color: rgba(255, 255, 255, 0.85);
}
.dark .ant-upload-list-item-card-actions .anticon {
  color: rgba(255, 255, 255, 0.45);
}
.dark .ant-upload-list-item-info .anticon-loading .anticon,
.dark .ant-upload-list-item-info .ant-upload-text-icon .anticon {
  color: rgba(255, 255, 255, 0.45);
}
.dark .ant-upload-list-item .anticon-close {
  color: rgba(255, 255, 255, 0.45);
}
.dark .ant-upload-list-item .anticon-close:hover {
  color: rgba(255, 255, 255, 0.85);
}
.dark .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: rgba(255, 255, 255, 0.08);
}
.dark .ant-upload-list-item-error,
.dark .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.dark .ant-upload-list-item-error .ant-upload-list-item-name {
  color: #a61d24;
}
.dark .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #a61d24;
}
.dark .ant-upload-list-picture .ant-upload-list-item,
.dark .ant-upload-list-picture-card .ant-upload-list-item {
  border: 1px solid #434343;
}
.dark .ant-upload-list-picture .ant-upload-list-item:hover,
.dark .ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.dark .ant-upload-list-picture .ant-upload-list-item-error,
.dark .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #a61d24;
}
.dark .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.dark .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.dark .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.dark .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #e6d9d8;
}
.dark .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.dark .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #a61d24;
}
.dark .ant-upload-list-picture-card .ant-upload-list-item-info::before {
  background-color: rgba(0, 0, 0, 0.5);
}
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.dark pload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  color: rgba(255, 255, 255, 0.85);
}
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.dark .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.dark .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: rgba(255, 255, 255, 0.04);
}
